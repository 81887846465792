@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply text-base h-full overflow-visible;
}

body {
    @apply h-full overflow-visible;
}

#root {
    @apply h-full w-full fixed overflow-visible;
}

b {
    @apply font-bold;
}

i,u {
    @apply font-medium;
}

@media not screen and (hover) {
    * {
        cursor: default !important;
    }

    html {
        @apply text-4xl leading-6;
    }
}

@supports (-webkit-touch-callout: none) {
    #test span, #test p, #test label {
        @apply leading-8;
    }
}

@layer base {
    .button-primary {
        @apply block p-2 rounded-lg text-kura bg-jajko hover:-translate-y-0.5 transition-all font-medium select-none w-full text-center;
    }
}

.animate-fade-in {
    animation: fade-in 0.5s ease;
    animation-fill-mode: forwards;
    animation-delay: 0ms;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animate-fade-in-delay {
    animation: fade-in 1000ms ease;
    animation-fill-mode: forwards;
    animation-delay: 1000ms;
    opacity: 0;
}

.animate-move-in {
    animation: move-in 0.2s ease;
    animation-fill-mode: forwards;
    animation-delay: 0ms;
}

.animate-move-in-50 {
    animation: move-in 0.2s ease;
    animation-fill-mode: forwards;
    animation-delay: 50ms;
}

@keyframes move-in {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.animate-move-from-top {
    animation: move-from-top 0.2s ease;
    animation-fill-mode: forwards;
    animation-delay: 0ms;
}

@keyframes move-from-top {
    0% {
        transform: translateY(-100vh);
    }

    100% {
        transform: translateY(0);
    }
}

span.blank-answer {
    @apply ring-offset-2 ring-2 ring-jajko px-4 mx-1 leading-8 rounded bg-jajko shrink-0 text-center text-white select-none;
}

span.inline-number {
    @apply ring-offset-2 ring-2 ring-jajko px-2 mx-1 rounded bg-jajko shrink-0 text-center text-white select-none;
}

#test {
    will-change: scroll-position;
    scroll-behavior: smooth;
}

.sidebar {
    @apply flex h-full relative;
}

.sidebar > .backdrop {
    @apply pointer-events-none opacity-0;
}

.sidebar.open > .backdrop {
    @apply pointer-events-auto opacity-100;
}

.sidebar>.content {
    will-change: transform;
    @apply mobile:-translate-x-full mobileWidth:-translate-x-full;
}

.sidebar.open>.content {
    @apply mobile:-translate-x-4 mobileWidth:-translate-x-4;
}

.sidebar.open > .catcher {
    @apply pointer-events-none;
}

.sidebar > .catcher {
    will-change: opacity;
}

@keyframes tp-header-d-tr-in {
    0% {
        rotate: -45deg;
    }

    100% {
        rotate: 0deg;
    }
}

@keyframes tp-header-d-tl-in {
    0% {
        rotate: 45deg;
    }

    100% {
        rotate: 0deg;
    }
}

@keyframes tp-header-d-tr {
    0% {
        rotate: 0deg;
    }

    50% {
        rotate: -45deg;
    }

    100% {
        rotate: 0deg;
    }
}

@keyframes tp-header-d-tl {
    0% {
        rotate: 0deg;
    }

    50% {
        rotate: 45deg;
    }
    
    100% {
        rotate: 0deg;
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.initial-load #dubek_tp_tr,
.initial-load #dubek_tp_bl {
    animation: tp-header-d-tr-in 1s ease;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.initial-load #dubek_tp_tl,
.initial-load #dubek_tp_br {
    animation: tp-header-d-tl-in 1s ease;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.initial-load #to,
.initial-load #from {
    animation: fade 0.5s ease;
    opacity: 0;
    animation-direction: reverse;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

.loaded .animate-tp-header #to {
    animation: fade 0.5s ease;
    opacity: 0;
    animation-direction: reverse;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.loaded .animate-tp-header #from {
    animation: fade 0.5s ease;
    animation-fill-mode: forwards;
    opacity: 1;
    animation-delay: 0s;
}

.loaded .animate-tp-header>#dubek_tp_tr,
.loaded .animate-tp-header>#dubek_tp_bl {
    animation: tp-header-d-tr 1s ease;
    animation-fill-mode: forwards;
    animation-delay: 0s;
}

.loaded .animate-tp-header>#dubek_tp_tl,
.loaded .animate-tp-header>#dubek_tp_br {
    animation: tp-header-d-tl 1s ease;
    animation-fill-mode: forwards;
    animation-delay: 0s;
}

@keyframes subj-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(calc(var(--subj-index) * -100% - 100px));
    }
}

@keyframes subj-in {
    0% {
        opacity: 0;
        transform: translateY(calc(var(--subj-index) * -100% - 100px));
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.initial-load .animate-subj.in >* {
    animation: subj-in 0.5s ease;
    animation-fill-mode: both;
    animation-delay: calc(var(--subj-index) * 100ms + 2000ms);
}

.initial-load .animate-subj.out >* {
    animation: subj-out 0.5s ease;
    animation-fill-mode: both;
    animation-delay: calc(var(--subj-index) * 100ms + 2000ms);
}

.loaded .animate-subj.in > * {
    animation: subj-in 0.5s ease;
    animation-fill-mode: both;
    animation-delay: calc(var(--subj-index) * 100ms + 1000ms);
}

.loaded .animate-subj.out > * {
    animation: subj-out 0.5s ease;
    animation-fill-mode: both;
    animation-delay: calc(var(--subj-index) * 100ms);
}

.animate-subj:not(.in):not(.out) {
    opacity: 0 !important;
}

.animate-bg-dubek-in {
    animation: fade-in 1s ease;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-delay: calc(var(--dub-index) * 250ms + 500ms);
}

a:not(.button-primary) {
    @apply hover:underline font-bold;
}

.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 50ms linear 25ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 25ms linear;
}